.options-list {
    background-color: #282828;
}

.options-item {
    --background: transparent;
    height: 45px;
    --detail-icon-color: white;
    --detail-icon-opacity: 1;
}

.options-popover {
    --background: #282828;
}
