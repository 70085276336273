/* Fixes issue with chrome 114+ where popovers don't display */
/* TODO remove this when updating to latest ionic 6 or 7+ */
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

/* Hide scroll bars that cause issues on cypress/electron/linux size issues */
ion-content.no-scroll-bar {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;
}

ion-footer {
    padding-bottom: env(safe-area-inset-bottom);
}

ion-content.no-scroll-bar::-webkit-scrollbar {
    display: none;
}

ion-select{
    --placeholder-color: white;
    --placeholder-opacity: 0.9;
}

.scrollable-contents.no-scroll-bar {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;
}

.scrollable-contents.no-scroll-bar::-webkit-scrollbar {
    display: none;
}

.separator {
    border-bottom: 1px solid var(--ion-color-step-300);
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    color: white;
}

.role-icons {
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px auto;
}

.role-icons.small {
    height: 15px;
    width: 15px;
}

.role-icons.small {
    background-size: 15px auto;
}

.role-icons.referee {
    background-image: url("../assets/images/official-role/referee.png");
    background-repeat: no-repeat;
}

.role-icons.assistant {
    background-image: url("../assets/images/official-role/assistant.png");
    background-repeat: no-repeat;
}

.role-icons.fourth-official {
    background-image: url("../assets/images/official-role/fourth-official.png");
    background-repeat: no-repeat;
}

.role-icons.fourthOfficial {
    background-image: url("../assets/images/official-role/fourth-official.png");
    background-repeat: no-repeat;
}

.role-icons.observer {
    background-image: url("../assets/images/official-role/observer.png");
    background-repeat: no-repeat;
}

.icon-filters {
    color: white;
}


.ion-content::part(scroll) {
    --offset-bottom: auto !important;
}

.ion-card-transparent {
    --background: transparent;
    background-color: transparent;
}

.bottom {
    margin-bottom: 10px;
}

.bottom-padding {
    --padding-bottom: 25px;
}

.bottom-padding {
    padding-bottom: 25px;
}

.ion-item-label {
    font-size: small;
}

.ion-col-no-padding {
    --ion-grid-column-padding: 0;
}

.white-text-and-font {
    font-size: 16px;
    color: white;
}

.notch-safe{
    padding-top: env(safe-area-inset-top);
}

.align-right {
    text-align: right;
}

.text-xSmall {
    font-size: 10px;
}

.text-small {
    font-size: 12px;
}

.text-medium {
    font-size: 14px;
}

.text-large {
    font-size: 20px;
}

.dark-color {
    color: var(--ion-color-gray);
}

.text-black {
    color: black;
}

.text-weight-bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

.align-center {
    text-align: center;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.rounded-corners {
    border-radius: 10px;
}

.rounded-corners-small {
    border-radius: 5px;
}

.item-no-padding-start {
    --padding-start: 0;
}

.item-no-padding-end {
    --padding-end: 0px;
    --inner-padding-end: 0px;
}

.indent-left {
    padding-left: 20px;
}

.indent-left-small {
    padding-left: 10px;
}

.info-box {
    border: 1px solid white;
    border-radius: 0;
}

.label-image {
    height: 16px;
    position: absolute;
    top: 14px;
    margin-left: 6px;
}

.small-input {
    max-width: 70px;
}

ion-popover {
    --width: 280px;
}

.borderless-input {
    border: none;
}

.transparent-input {
    --background: transparent;
    background-color: transparent;
    outline: none;
    width: 100%
}

.align-left-center {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.padding-small {
    padding: 5px
}

.swiper {
    position: fixed;
    bottom: 0px;
    padding-bottom: 3px;
}

/* target all bullets */
span.swiper-pagination-bullet.swiper-pagination-bullet {
    background-color: var(--ion-color-step-500);
    opacity: 1;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--ion-color-primary);
    opacity: 1;
}
