.mgm-heading {
    font-size: 35px;
    line-height: 30px;
    margin-top: 30px;
}

.gift-emoji {
    position: relative;
    width: 50px;
    top: 8px;
    left: 7px;
    font-size: 53px;
}

.summary-box {
    background-color: var(--ion-item-background);
    padding: 10px;
    border-radius: 5px;
}

.align-right {
    margin-left: auto;
}

.width-100 {
    width: 100%;
}

.buttons-container {
    margin-top: 30px;
}

.button-icon {
    margin-right: 5px;
}

.qr-modal-item {
    margin: 0 auto;
}

.qr-modal-name {
    margin: 30px 0;
    font-size: 30px;
}
