
/* Fixed the issue with this css workaround: */
[popover].popover-viewport {
    display: initial;
    position: initial;
    margin: initial;
    border: initial;
    background: initial;
    padding: initial;
    width: initial;
    height: initial;
    overflow: initial;
    inset: initial;
    color: initial;
}

/* Ionic v7 removes default hidden styles */
[hidden] {
    display: none !important;
}
