.autocomplete{
    height: 0;
    overflow: hidden;
    background-color: #1c1c1c;
    transition: height 0.2s ease-out;
}

.autocomplete.open{
    border-bottom:
            2px solid var(--ion-color-primary);
    height: 50vh;
    overflow: scroll;
}

.autocomplete.open.flex {
    display: flex;
    height: 55px;
    white-space: nowrap;
}
