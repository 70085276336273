.highest-scoring-review .ball {
    width: 150px;
    display: block;
}

.highest-scoring-review .stats-container .content-row {
    display: flex;
    flex-flow: row nowrap;
}
.highest-scoring-review .stats-container  {
    align-content: flex-start;
    flex-wrap: nowrap;
}
.highest-scoring-review .stats-container .content {
    font-size: 1rem;
}
.highest-scoring-review .stats-container .row {
    align-items: center;
    justify-content: space-between;
}
.highest-scoring-review .stats-container .info .col  {
    padding: 0;
}
.highest-scoring-review .stats-container .header {
    padding-right: 20px;
    margin-right: 0;
    width: 45%;
    flex-grow: 0;
}

.highest-scoring-review .stats-container .stat .main-header {
    width: 44%;
    text-align: end;
    margin-right: 5px;
}

.highest-scoring-review .stats-container .header > div {
    transform: skew(10deg);
    text-align: end;
}

.highest-scoring-review .stats-container .header .icon.start {
    margin: -25px -10px -7px 0;
}
.highest-scoring-review .big-stat {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.highest-scoring-review .highest-scoring {
    margin: 50px 0;
}

.review-share-story .highest-scoring-review .highest-scoring {
    margin: 40px 0;
}

.review-share-feed .highest-scoring-review {
    transform: scale(0.9);
    transform-origin: top left;
}
.review-share-feed .highest-scoring-review .ball {
    margin: 0;
}
.review-share-feed .highest-scoring-review h2 {
    font-size: 16px;
}
.review-share-feed .highest-scoring-review .big-stat {
    margin-top: 5px;
    text-align: right;
}
.review-share-feed .highest-scoring-review .big-stat .big-stat-number {
    font-size: 50px;
    line-height: 50px;
}
.review-share-feed .highest-scoring-review .balls-n-stats {
    transform: scale(0.9);
    display: flex;
    justify-content: space-between;
    align-items: start;
}
