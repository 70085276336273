.half-pitch {
    width: 100%;
}

.half-pitch-value {
    position: absolute;
    color: var(--ion-color-primary);
    font-weight: bold;
    text-shadow: 1px 1px 3px var(--ion-color-primary-contrast);
    font-size: 28px;
    width: 28%;
    text-align: center;
}

.half-pitch-value-0,
.half-pitch-value-1,
.half-pitch-value-2 {
    top: 26%;
}

.half-pitch-value-3,
.half-pitch-value-4,
.half-pitch-value-5 {
    top: 52%;
}

.half-pitch-value-6,
.half-pitch-value-7,
.half-pitch-value-8 {
    top: 78%;
}

.half-pitch-value-0,
.half-pitch-value-3,
.half-pitch-value-6 {
    left: 4%;
}

.half-pitch-value-1,
.half-pitch-value-4,
.half-pitch-value-7 {
    left: 36%;
}

.half-pitch-value-2,
.half-pitch-value-5,
.half-pitch-value-8 {
    left: 68%;
}