.cards-container .stat.yellow {
    background-color: var(--ion-yellow-card-background);
    flex: 1 1 45%;
}
.cards-container .stat.red {
    background-color: var(--ion-red-card-background);
    flex: 1 1 45%;
}
.stats-container .stat.total-matches {
    flex: 1 1 95%;
}
.stats-container .stat.average-distance {
    flex: 1 1 95%;
}
.stats-container .stat.total-sprints {
    flex: 1 1 95%;
}
.start-review .cards-container .card-icon .card2{
    left: 12px;
}

.stat.average-distance {
    /*margin-left: 5px;*/
}
.stat.total-sprints {
    /*margin-right: 5px;*/
}
.stat.total-matches {
    /*margin: 0 5px;*/
}
.stats-container .inset {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 100%;
    gap: 5px;
    justify-content: space-between;
    padding: 0 20px;
}

.start-review h2.celebrate {
    font-size: 20px;
    padding: 10px 0;
}

.start-review h2.share-stats {
    font-size: 20px;
    padding: 10px 0;
    display: none;
}

.start-review .stats-container .stat .main-header{
    font-size: 1.4rem;
}

.start-review .stats-container{
    margin-bottom: 50px;
}

.review-share-story .start-review .stats-container{
    margin-bottom: 10px;
}

.story-container:last-child .start-review h2.celebrate {
    display: none;
}

.story-container:last-child .start-review h2.share-stats {
    display: block;
}

.review-share-feed .start-review-container{
    display: flex;
    flex-direction: row;
}

.review-share-feed .stats-container{
    transform: scale(0.6);
    transform-origin: top left;
}

/*.review-share-feed .stats-container .stat .main-header {*/
/*    font-size: 1rem;*/
/*}*/

.review-share-story .start-review h2 {
    display: none;
}

.review-share-story .cards-container{
    margin-top: 0;
}

.review-share-story .start-review .stats-container {
    gap: 5px;
}
.review-share-story .start-review .stats-container .stat {
    gap: 0;
}
.review-share-story .start-review .stats-container .stat .content {
    font-size: 3.5rem;
}
