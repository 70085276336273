/* Page styles */

.timer-options-container p {
    color: white;
}
.watch-frame {
    /* to fit iphone SE */
    width: 342px;
    height: 342px;
    border: 10px solid #242424;
    border-radius: 50px;
    margin: 0 auto;
    padding-top: 5px;
}

span.timer-label {
    font-size: 19px;
    color: white;
    font-weight: normal;
    border: 1px dotted grey;
    padding: 2px 5px;
    display: flex;
    align-items: center;
}

.timer-label.duplicate-timer {
    border: 1px dashed #d90000;
}

.timer-radio-label {
    width: 85vw;
}

.timer-radio-label p {
    text-align: left;
    padding-left: 10px;
}
.timer {
    cursor: pointer;
}


/* Watch styles taken from refsix-js-watch (phone watch) and edited */

.timer {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.teams-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100px;
}

.teams-group .team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1e1e1e;
    border-radius: 0.625rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    padding-top: 0.375rem;
    width: 40%;
    height: 100%;
}

.teams-group .team.selected {
    background-color: #b3d037;
    color: #000;
}

.teams-group .team .team-name {
    margin: 0;
    font-weight: 700;
    line-height: 2.125rem;
}

.teams-group .team .team-out-of-score,
.teams-group .team .team-score {
    margin: 0;
    padding: 0;
}

.teams-group .team .team-penalties-score {
    display: inline-block;
}

.teams-group .team .team-penalties-score .team-score {
    text-align: center;
}

.teams-group .team .team-penalties-score .team-out-of-score {
    float: right;
    font-size: 1.5rem;
    margin-top: -2.5rem;
    padding-right: 0.625rem;
}

.timers-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timers-group .main-timer {
    font-size: 3.75rem;
    margin-top: 0.625rem;
}

.timers-group .sin-bin-timer {
    display: none;
    font-size: 1.25rem;
    line-height: 1.25rem;
    padding: 0;
    color: #f9d401;
}

.timers-group .sin-bin-timer.away,
.timers-group .sin-bin-timer.home {
    width: 50%;
    text-align: center;
    display: block;
}

.timers-group .sin-bin-timer.away {
    margin-left: 50%;
}

.timers-group .timer-left-right-group,
.timers-group .timer-secondary-group {
    font-size: 2.188rem;
}

.timers-group .timer-secondary-group .timer {
    display: inline-block;
    padding: 0.3125rem;
}

.timers-group .timer-left-right-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.timers-group .timer-left-right-group,
.timers-group .timer-secondary-group {
    margin-top: 5px;
}

.timers-group .timer-left-right-group .timer {
    padding: 0.3125rem;
}

.timers-group {
    color: #fff;
    margin-top: 20px;
}

.big-timers .timers-group {
    margin-top: 0;
}

.timers-group.injury-time .additional {
    background: #b3d037;
    color: #000;
    padding: 0.3125rem;
    border-radius: 0.9375rem;
}

.timers-group.injury-time .countdown,
.timers-group.injury-time .countup,
.timers-group.injury-time .elapsed {
    color: hsla(0, 0%, 100%, 0.5);
}

.big-timers .teams-group {
    margin-top: 20px;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    order: 2;
    height: auto;
}

.big-timers .teams-group .team {
    flex-direction: row;
    width: unset;
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
}

.big-timers .teams-group .team:last-child .team-name {
    order: 1;
}

.big-timers .teams-group .team .team-name {
    padding: 0.3125rem;
    font-weight: 400;
    font-size: 1.625rem;
}

.big-timers .teams-group .team .team-score {
    background-color: transparent;
    padding: 0.3125rem;
    border-radius: 0.625rem;
    font-weight: 700;
    font-size: 1.75rem;
}

.big-timers .timers-group {
    order: 1;
}

.big-timers .timers-group .main-timer {
    color: #b3d037;
    font-size: 4.375rem;
    margin-top: 1.75rem;
    font-weight: 700;
    text-align: center;
}

.big-timers .timers-group .timer-left-right-group,
.big-timers .timers-group .timer-secondary-group {
    font-size: 2.813rem;
    margin-top: 1.4375rem;
}

.big-timers .timers-group .sin-bin-timer {
    order: 3;
}

section.main-screen-section .main-screen {
    display: flex;
    flex-direction: column;
}

.watch-frame div,
.watch-frame p {
    text-align: center;
}

progress {
    display: none;
}


.teams-group .team p {
    font-size: 1.5rem;
}

@media screen and (min-width: 320px) {
    .buttons-group,
    .timers-group {
        min-height: 10rem;
    }

    .big-timers .timers-group {
        height: 15rem;
    }

    .big-timers .timers-group .main-timer {
        padding-top: 0.5625rem;
    }
}
