.notes-area {
    padding: 5px;
    color: white;
    height: 150px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: left;
    --placeholder-color: #fff;
    --color: #fff;
}
