
.color-picker-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -10px;
    justify-content: center;
    margin-top: 10px;
}

.color-picker {
    border-radius: 100%;
    height: 30px;
    width: 30px;
    border: none;
    margin-right: 17px;
    margin-bottom: 10px;
}
