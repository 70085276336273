.top-match-value {
    color: black;
    text-transform: lowercase;
    font-weight: 900;
}
.top-matches-review h2 {
    font-size: 18px;
    text-align: left;
    margin: 10px 15px 0;
}
.top-matches-review .stats-container .stat {
    display: unset;
    flex: unset;
}

.top-matches-review .stats-container .stat .main-header {
   font-weight: bold;
}

.top-matches-review .stats-container .row{
    padding: 0;
}
.top-matches-review .stats-container .stat .main-header {
    padding: 0 0 0 20px;
    font-size: 20px;
    display: block;
    margin-right: 30px;
}
.top-matches-review .stats-container .stat .main-header .icon{
    width: 40px;
    height: 40px;
}
.stats-container .stat.highest-scoring-header .icon {
    background: url('../../assets/images/review/ball.png') no-repeat center center;
    background-clip: border-box;
    background-size: cover;
}
.stats-container .stat.most-cards-header .icon {
    background: url('../../assets/images/review/white-cards.png') no-repeat center center;
    background-clip: border-box;
    background-size: cover;
    top: -7px;
    right: -15px;
}
.stats-container .stat.highest-distance-in-match-header .icon {
    background: url('../../assets/images/review/distance.png') no-repeat center center;
    background-clip: border-box;
    background-size: cover;
    top: -22px;
}
.stats-container .stat.hardest-match-header .icon {
    background: url('../../assets/images/review/sprint.png') no-repeat center center;
    background-clip: border-box;
    background-size: cover;
}
.top-matches-review .fixture-card {
    margin: 5px 15px;
    padding: 2px;
}
.top-matches-review .icon {
    position: fixed;
}
.top-matches-review .stat .match  {
    flex-direction: row;
    min-height: 75px;
}
.top-matches-review .stat .row.vs {
    justify-content: center;
    font-weight: 900;
    font-style: italic;
}
.top-matches-review .stat .row.home-team {
    justify-content: end;
}
.top-matches-review .stat .row.away-team {
    flex-direction: row-reverse;
    justify-content: start;
}

.top-matches-review .stat .row.away-team,
.top-matches-review .stat .row.home-team {
    flex-basis: 40%;
}

.top-matches-review .stats-container .team-container {
    flex-grow: 1;
}
.top-matches-review .stats-container .team-container .team-name-container .team-name{
    font-size: 16px;
}

.review-share-story .review.top-matches-review {
    margin-top: 5px;
}

.review-share-story .top-matches-review .fixture-card{
    margin: 5px 0;
}

.review-share-story .top-matches-review .stat .match  {
    flex-direction: row;
    min-height: 70px;
    align-items: center;
    justify-content: center;
}
