/* hide built-in card count for that match*/
.most-cards-review .card-map ion-item {
    display: none;
}
.most-cards-review .stats-container .stat .main-header {
    flex-basis: 160px;
    text-align: end;
}
.most-cards-review .total-cards .cards-container .col {
    align-items: center;
}
.most-cards-review .total-cards .cards-container .card-icon {
    /*top: 0;*/
    width: 35px;
    height: 50px;
    position: relative;
    top: unset;
}
.most-cards-review .total-cards  .cards-container .content {
    font-size: 30px;
    font-style: italic;
    align-items: center;
}
.most-cards-review .total-cards  .cards-container .cards-red,
.most-cards-review .total-cards  .cards-container .cards-yellow
{

justify-content: space-around;
}
.most-cards-review .total-cards  .cards-container .cards-red .card-name,
.most-cards-review .total-cards  .cards-container .cards-yellow .card-name
{
    width: 30%;
}
.most-cards-review .total-cards  .cards-container .header {
    font-size: 18px;
}
.most-cards-review .total-cards .cards-container .card-icon .card1 {
    width: 20px;
    height: 28px;
    left: 2px;
    top: 10px;
    position: relative;

}
.most-cards-review .total-cards .cards-container .card-icon .card2 {
    width: 20px;
    height: 28px;
    left: 12px;
    top: -12px;
    position: relative;

}

.stats-container .match-cards {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.stats-container .match-cards .team-container {
    font-size: 25px;
    flex-basis: 40%;
}
.stats-container .match-cards .cards {
    width: 70px;
    height: 65px;
    position: relative;
}
.stats-container .match-cards .cards-label {
    position: absolute;
    font-weight: 900;
    font-size: 25px;
}

.stats-container .match-cards .cards-container > .card-icon {
    min-height: 50px;
    top: unset;
}
.stats-container .match-cards .cards-container{
    margin: 0;
    align-items: center;
    justify-content: center;
}

.most-cards-review .match-cards .cards-container .card-icon {
    width: 70px;
    height: 80px;
}
.most-cards-review .match-cards .cards-container .card1 {
    background-color: #f3f80e;
    left: 12px;
    top: 10px;
}
.most-cards-review .match-cards .cards-container .card2 {
    background-color: #a30c0c;
    top: 14px;
    left: 30px;
}
.most-cards-review .match-cards .cards-container .cards-label {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.most-cards-review .stats-container .content {
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-end;
}
.most-cards-review .stats-container {
    background-color: #00000000;
}
.most-cards-review .stats-container .stat.most-cards-match .content {
    font-size: 1rem;
}
.most-cards-review .cards-container {
    background-color: transparent;
}
.most-cards-review .total-cards .cards-container {
    flex-wrap: nowrap;
}
.most-cards-review .total-cards .cards-container .content {
    flex-grow: unset;
    flex-direction: unset;
}
.most-cards-review .stats-container .header {
    margin-right: 1px;
}
.most-cards-review .most-cards-match {
    align-items: center;
}
.most-cards-review .match > .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.card-review-stats {
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.card-review-stats > * {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    flex-basis: 25%;*/
/*}*/
.card-review-stats .big-stat {
    flex-basis: 50%;
    text-align: right;
    justify-content: end;
}
.card-review-stats .number-of-cards {
    font-size: 25px;
    font-weight: bold;
    padding: 5px 0;
}
.card-review-stats .colour-label {
    text-transform: uppercase;
    color: var(--ion-color-gray);
    font-size: 14px;
}
.most-cards-review .yellow-card {
    background-color: #fff633;
    flex: 1 1 45%;
}
.most-cards-review .red-card {
    background-color: #f00;
    flex: 1 1 45%;
}
.review-share-feed .most-cards-review {
    display: flex;
    flex-direction: row;
    /*transform: scale(0.5);
    */
    /*transform-origin: top center;
    */
}
.review-share-feed .most-cards-review h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 10px;
}

.review-share-feed .most-cards-review .card-container {
    margin-left: -200px;
    position: absolute;
    right: 0;
    top: 60px;
    width: 30%;
    gap: 20px;
    height: 72%;
}
.review-share-feed .most-cards-review .card-container .red-card, .review-share-feed .most-cards-review .card-container .yellow-card {
    border-radius: 10px 0 0 10px;
}

.review-share-story .most-cards-review .bottom-bit{
    transform: scale(0.8);
    transform-origin: top left;
}

.review-share-story .most-cards-review .map-container{
    width: 270px;
    margin:  0 auto;
}

.review-share-story .most-cards-review .match-header{
    width: 90%;
    margin: 0 auto;
}

.review-share-story .card-container .card .content {
    font-size: 30px;
}

.review-share-feed .most-cards-review {
    display: flex;
    flex-direction: row
}

.review-share-feed .most-cards-review .top-bit {
    flex: 1 1 70%;
}

.review-share-feed .most-cards-review .bottom-bit {
    flex: 1 1 30%;
}

.review-share-feed .most-cards-review .card-container {
    position: absolute;
    right: 0;
    top: 20px;
    width: 100%;
    flex-direction: column;
    flex-flow: column;
    gap: 10px;
    height: 72%;
    padding-top: 0;
}
.review-share-feed .most-cards-review .card-container .card {
    flex: 1 1 30%;
    gap: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.review-share-feed .most-cards-review .card-container .card .content {
    font-size: 2rem;
}
.review-share-feed .most-cards-review .fixture-card {
    margin: 5px;
}
.review-share-feed .most-cards-review.review h2 {
    font-size: 10px;
    text-align: left;
    margin-left: 10px;
}
