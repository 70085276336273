.match-officials{
    padding: 5px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.match-official{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
