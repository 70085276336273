.old-create-match .create-match-step,
.old-create-match .create-match-card-title {
    display: none;
}

.old-create-match .row-container {
    margin: 0;
}

.old-create-match .template-name {
    color: var(--ion-color-primary);
    font-weight: bold;
    font-size: 14px;
}
