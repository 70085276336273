.add-icon {
    font-size: 23px;
    --ionicon-stroke-width: 50px;
}

.ion-fab-button {
    margin-top: 10px;
    margin-bottom: 20px;
}

.ion-fab-button[data-desc]::after {
    position: absolute;
    content: attr(data-desc);
    z-index: 1;
    left: 55px;
    bottom: 0;
    height: 30px;
    align-items: center;
    background-color: var(--ion-color-primary);
    padding: 5px 10px;
    border-radius: 30px;
    color: black;
    display: flex;
}

.knvb-fab-button.ion-fab-button {
    --background: transparent;
}

.knvb-fab-button.ion-fab-button[data-desc]::before {
    content: "";

    color: white;
    background-image: url("../assets/images/icon/knvb-logo-vector-2022.svg");
    display: block;
    position: absolute;
    top: -9px;
    left: -9px;
    width: 60px;
    height: 60px;
    z-index: 5;
}
.knvb-fab-button.ion-fab-button[data-desc]::after {
    background-color: var(--knvb-orange);
    color: white;
    /*font-weight: bold;*/
    /*text-shadow: 0px 0px 3px black;*/
}

.knvb-fab-icon{
    font-size: 32px;
}

/* For some reason the fab in the tab bar isn't centered on rtl languages */
.rtl #ionFab{
    margin-left: -26px;
}

.backdrop {
    filter: opacity(70%);
    background-color: #000000;
}


.fab-button {
    margin-bottom: env(safe-area-inset-bottom);
    z-index: 100; /* Ionic uses z index 100 to hide the tabBar */
}

.showTabs .fab-button {
    z-index: 101; /* Ionic uses z index 101 to show the tabBar */
}

/* Swap between filled and outline icons */
.tab-icon-selected {
    display: none;
}

.tab-selected{
    .tab-icon-unselected {
        display: none;
    }

    .tab-icon-selected {
        display: block;
    }
}
