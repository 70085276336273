.free-trial-button {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  background-color: #242424;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 5px;
}
