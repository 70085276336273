.button-date {
    background-color: transparent;
    --background: transparent;
    --ripple-color: transparent;
    --color: transparent;
    --box-shadow: transparent;
}

.icon-date {
    color: white;
}

.filters-officials-label {
    margin-left: 20px;
    align-items: center;
}

.reset-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 5px;

}

.reset-button {
    --background: transparent;
    background-color: transparent;
    color: var(--ion-color-primary);
}

.add-remove-button-container {
    display: flex;
    align-items: center;
    width: 85px;
    height: 35px;
    border-radius: 4px;
    justify-content: space-between;
    background-color: var(--ion-tab-bar-background);
}

.button-add-remove {
    border-radius: 99px;
    background-color: var(--ion-color-primary);
    justify-content: center;
    align-items: center;
    display: flex;
}

.icon-add-remove {
    color: var(--ion-color-primary);
}

.matches-with {
    display: flex;
    align-items: center;
}

.matches-with-text {
    margin-left: 8px;
}

.no-keywords-text {
    font-size: 14px;
    color: var(--ion-color-gray);
    padding-left: 5px;
}

.date-range-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.from-to {
    height: 100%;
    justify-content: space-around;
    display: grid;
}

.col-no-padding {
    padding: 0;
}

.right {
    padding-right: 5px;
}

.left {
    padding-left: 5px;
}

.filters-toggle {
    padding-inline: 0;
}

.row-date-picker-container {
    background-color: var(--ion-item-background);
}
