.story-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 0 0 0;
    position: relative;
    align-items: end;
}

.review-header-story-spacer {
    height: 25px;
    position: relative;
}

.review-share-story .review-header-story-spacer {
    height: 52px;
}

.story-header .top-logo {
    position: relative;
    color: #0d0d0d;
    font-size: 80px;
    font-weight: 900;
    font-style: italic;
    margin-left: 33px;
    z-index: 2;
}
.story-header .top-logo-title {
    margin: 0 10px;
    font-size: 26px;
    font-style: italic;
    display: flex;
    align-items: center;
    height: 100px;
    /*gap: 5px;*/
    /*flex-wrap: wrap;*/
}
.story-header .top-logo-title span:last-child {
    color: #d5e830;
    font-weight: bold;
    overflow-wrap: anywhere;
}
.story-header .thingy-container{
    margin-top: 28px;
    position: relative;
}

.story-header .border-thing{
    display: flex;
    width: 25%;
    height: 53px;
    border-radius: 0 1000px 1000px 0;
    border: 8px solid #d5e830; /* Outline color and thickness */
    background: transparent; /* No background */
    top: -25px;
    left: -10px;
    position: absolute;
    z-index: 1;
}
.story-header .white-thing{
    flex-basis: 80%;
    height: 100px;
    border-radius: 0 1000px 1000px 0;
    background-color: #FFFFFF;
    align-items: center;
    color: #0d0d0d;
    font-size: 80px;
    font-weight: 900;
    font-style: italic;
    padding: 0 20px 0 10px;
    justify-content: flex-end;
    z-index: 1;
}
.story-header .dateBadge {
    width: 47px;
    height: 47px;
}
.story-header .content {
    display: grid;
    align-items: center;
}
.story-header .logo {
    margin: 0 4px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 70px;
}
.story-header .title {
    grid-column:  2 / 4;
    grid-row: 1;
    text-transform: uppercase;
    font-weight: bolder;
}
.story-header .subtitle {
    grid-row: 2;
    grid-column: 1 / 4;
    padding-left: 4px;
    font-size: 0.9em;
}
.story-top-image {
    display: none;
}
.review-share-story .story-top-image {
    display: block;
    position: relative;
    z-index: 1;
}

.review-share-story .story-header {
    z-index: 1;
}
.review-share-story .story-header .border-thing{
    z-index: 2;
}

.review-share-story .story-header {
    /*padding-top: 15px;*/
    z-index: 1;
}
.review-share-story .story-header .border-thing{
    top: -28px;
}
.review-share-story .story-header .top-logo{
    font-size: 50px;
    height: 58px;
}
.review-share-story .story-header .white-thing,
.review-share-story .story-header .top-logo-title {
    height: 58px;
}

