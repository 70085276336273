.login-button {
    font-weight: bold;
    border-radius: 5px;
    height: 50px;
    margin-top: 15px;
}

.card-top-container {
    display: flex;
    justify-content: space-between;
    font-size: smaller;
}

.card-middle-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: white;
}

.team-name-left {
    display: inline-block;
    font-weight: bold;
    justify-content: flex-end;
    font-size: small;
}

.team-name-right {
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    font-size: small;
}

.fixture-card {
    background-color: #242424;
    margin: 15px;
    padding: 5px;
}

.team-name-color-container {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: center;
    width: 100%;
}

.team-name-color-container-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.align-vertically {
    display: flex;
    align-items: center;
}

.log-half-container {
    text-align: center;
    display: flex;
    width: 90px;
    padding: 5px;
    justify-content: center;
    background-color: var(--ion-color-step-150);
    border-radius: 5px;
}

.log-middle-wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 80%;
}

.log-timestamp-text {
    color: var(--ion-color-gray);
    font-size: 10px;
}

.log-event-name-text {
    color: var(--ion-color-alto);
    font-size: 16px;
}

.log-minute-play-text {
    color: white;
}

.score-container {
    display: flex;
    margin: 5px;
    background-color: var(--ion-score-background);
    border-radius: 2px;
}

.kftm {
    width: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-top: -2px;
    font-size: 12px;
}

.score {
    font-weight: bold;
    padding: 2px;
}

.penalties-extra-time-container {
    border-color: var(--ion-color-primary);
    border-style: solid;
    border-width: 0.5px;
    margin-right: 10px;
    margin-left: 10px;
}

.penalties-extra-time-text {
    padding: 5px;
    color: var(--ion-color-primary);
}

.score-formatting {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-flex {
    display: flex;
    justify-content: space-between;
}

.input-item {
    --background: var(--ion-color-input);
    --padding-start: 0;
    border-radius: 5px;
    margin: 10px;
}

/**
    for where basic html inputs (not ion-inputs)
 */
input.date-input {
    color-scheme: dark;
    border: none;
    width: 90px;
    background: transparent;

}

input.date-input.with-time,
input.date-input.large {
    width: 130px;
}

.inner-label {
    padding-left: 20px;
}

.no-margin {
    margin: 0;
}

.focus {
    --border-color: var(--ion-color-primary);
    border-bottom-color: var(--ion-color-primary);
}

.button-header {
    text-transform: none;
}

.color-picker-modal {
    width: 50%;
    height: 35%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.input.create-match {
    font-size: 12px;
}

.input.create-match.danger {
    font-size: 45px;
}

.item-title .create-match-official-role {
    padding-left: 20px;
    font-size: 12px;
}

.item-title .create-match-team-setup {
    padding-left: 20px;
    font-size: 12px;
    flex: 0.3;
}

.select-text {
    font-size: 12px;
}

#ion-input-0-lbl {
    font-size: small;
}

#ion-input-1-lbl {
    font-size: small;
}

.native-input.sc-ion-input-ios {
    padding-left: 20px;
}

.native-input.sc-ion-input-md {
    padding-left: 0px;
}

.cards {
    display: flex;
    height: 8px;
    width: 5px;
    margin-right: 4px;
    border-radius: 1px;
}

.yellow {
    background-color: var(--ion-yellow-card-background);
}

.red {
    background-color: var(--ion-red-card-background);
}

.expandable-container {
    position: relative;
}

.tags-view {
    position: relative;
    padding: 5px 0;
}

.keyword-wrapper {
    display: inline-block;
    max-width: 90%;
}

.tag {
    --background: transparent;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid white;
    color: white;
    margin: 3px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal;
    max-width: 100%;
}

.tag.selected {
    background-color: var(--ion-color-primary);
    color: black;
}

.add-keyword-button {
    z-index: 5;
    display: inline-block;
    --background: transparent;
    background-color: transparent;
    border-style: none;
    color: grey;
    font-size: 17px;
    padding-right: 0;
}

.optional-text {
    font-size: 10px;
    color: var(--ion-color-medium-shade);
}

.bar-chart {
    padding: 5px;
    height: 250px;
}

.segment-stats {
    min-width: auto;
}

.row-segment-period {
    width: 100%;
}

.map-container {
    position: relative;
    padding: 0;
}

.round-bar-graph {
    padding: 5px;
    border-radius: 15px;
    color: black;
    transition: width 0.5s ease-in-out;
    background-color: #d5e830;
    width: 20%;
    min-width: 20%;
    display: block;
    min-height: 30px;
}

.round-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.round-bar-value {
    font-size: 12px;
    white-space: nowrap;
}

.col-round-bar {
    padding: 0;
}

.legend-values-container {
    margin-top: 20px;
}

.legend-values {
    display: flex;
    justify-content: center;
}

.graph-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background-color: var(--ion-item-background);
}

.graph-legend-item {
    color: black;
    flex: 1;
    margin-right: 1px;
    padding: 3px;
}

.round-bar-label {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
}

.legend-label {
    color: #8d8d8d;
    background-color: black;
    margin-right: 1px;
}

.values-text {
    font-size: 12px;
    font-weight: bold;
}


.semi-opaque {
    opacity: 0.3;
}
.overlay-wrapper {
    position: relative;
}
.overlay-text {
    position: absolute;
    top: 35%;
    text-align: center;
}
.no-upcoming-matches {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 150px;
    text-align: center;
    color: white;
}

.button-width {
    width: 90%;
}

.grey-background {
    color: white;
    --background: var(--ion-color-step-400);
}

.rounded-corners-top {
    border-radius: 10px 10px 0 0;
}

.rounded-corners-bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.align-text-end {
    text-align: end;
}

.align-text-end-padded {
    text-align: end;
    --padding-end: 35px;
}

.headline-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.headline-numbers-container {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    flex-direction: column;
    display: flex;
}

.comparison-bar {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
}

.comparison-item {
    height: 30px;
    transition: width 0.5s ease-in-out;
}

.ltr .comparison-item:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.rtl .comparison-item:first-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ltr .comparison-item:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.rtl .comparison-item:last-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.role-icons.roundBar {
    margin: 0 10px 0 20px;
}

.expandable-timings {
    margin-left: -10px;
    margin-right: -10px;
}

.expandable-timings .expand-button {
    display: none;
}

.expandable-timings.expandable {
    position: relative;
}

.expandable-timings.expandable .expand-button {
    position: absolute;
    top: 1px;
    right: 6px;
    padding: 1px 7px;
    z-index: 5;
    display: inline-block;
    background-color: transparent;
    border-style: none;
    color: white;
    font-size: 20px;
}

.accept-decline-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -2px;
    background-color: #242424;
    height: 50px;
}

.button-accept-decline {
    width: 100px;
    height: 30px;
    border-width: 1px;
    border-style: solid;
    color: white;
    background-color: #1a1a1a;
    border-radius: 5px;
}

.accept {
    border-color: green;
}

.decline {
    border-color: red;
}

.integration-cloud-button {
    color: white;
    margin-right: 10px;
}

.kick-off-side-dot {
    background-color: white;
    border-radius: 99px;
    height: 5px;
    width: 5px;
}

.kick-off-side-container {
    display: flex;
    width: 10%;
    align-items: center;
}

.left {
    justify-content: flex-start;
}


.right {
    justify-content: flex-end;
}

.live-indicator {
    border: 1px solid var(--ion-color-primary);
    color: var(--ion-color-primary);
    padding: 0 5px;
    text-transform: uppercase;
    margin-left: 5px;
    margin-right: 5px;
}
