.pro-lock {
    position: absolute;
    top: 40%;
    left: 30px;
    right: 30px;
    display: block;
    color: white;
    text-align: center;
    z-index: 99;
}
.find-more-text {
    color: white;
}

.pro-teaser {
    min-height: 65px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    align-items: center;
    z-index: 1;
}

.pro-teaser-click-blocker {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 98;
}

.teaser-button-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    z-index: 500;
}

.pro-teaser-button {
    margin-top: 10px;
}

.pro-teaser .img {
    width: 100%;
    opacity: 0.3;
    max-width: 500px;
    margin: 0 auto;
    display: block;
}
