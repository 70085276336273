.alert-button-cancel {
    font-weight: normal;
}

.v {
    font-weight: normal;
}

.alert-button.sc-ion-alert-ios:last-child {
    font-weight: normal;
}
