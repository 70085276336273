.next-game-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.next-game-title {
    font-size: small;
}

.row-timers {
    display: flex;
    justify-content: center;
}

.timer-countdown {
    width: 50px;
    height: 60px;
    background-color: #242424;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.time-text-style {
    font-size: larger;
    color: var(--ion-color-primary);
}

.time-text-when {
    font-size: small;
    color: var(--ion-color-step-400);
}

.header-end-button {
    width: 30px;
    height: 30px;
}

.ion-header::after {
    background-image: none;
    box-shadow: 0 1px 0 #343434 !important;
}
.plotted-points {
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
}

.plotted-points svg:first-child {
    position: absolute;
    top: 0;
    left: 0;
}

.player-item-row-reorder-delete-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40px;
}
