.mgm-banner{
    background-image: url('../../assets/images/mgm/refer-banner-bg.png');
    background-size: cover;
    margin: 10px;
    padding: 10px;
    font-size: 30px;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.mgm-banner-top{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: start;
}

.mgm-gift-icon{
    background: var(--ion-color-primary);
    border-radius: 15px;
    width: 70px;
    height: 70px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mgm-gift-icon ion-icon{
    font-size: 60px;
    color: black;
    padding: 5px 10px;
}
