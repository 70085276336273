
.social-login-divider{
    display: flex;
    flex-direction: row;
    /*    align them vertically to center */
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;


}

.social-login-divider > div {
    flex: 1;
    color: var(--ion-color-step-750);
    margin-left: 0;
    margin-right: 0;
}

.social-login-divider > .divider-line{
    height: 0;
    margin-top: 2px;
    border-bottom: 1px solid var(--ion-color-gray);
    /*flex-basis: 30%;*/
    background-color: red;
    margin-left: 10px;
    margin-right: 10px;
}
