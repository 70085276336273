.r6-form-link-button {
    --background: transparent;
    --color: var(--ion-text-color);
    text-transform: none;
}

.r6-form-link-button.clear {
    position: relative;
    top: 15px;
}

.danger {
    --background: var(--invalid-bg);
}

.error-message {
    font-size: small;
    color: red
}

ion-radio.radio-no-text {
    width: 30px;
}

ion-radio::part(container) {
    width: 30px;
    height: 30px;

    border-radius: 100%;
    border: 2px solid #ddd;
}

ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
}

ion-radio.radio-checked::part(container) {
    background: var(--ion-color-primary);
    border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;

    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: black;

    transform: rotate(45deg);
}
