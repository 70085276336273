.upload-text-container {
    display: grid;
    align-items: center;
    height: 80px;
}

.upload-text-wrapper {
    display: grid;
}

.item-height {
    height: 80px;
}

.small-space {
    margin-bottom: 5px;
}
