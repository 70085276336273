/* hide the heatmap segments and the adjust pitch position link*/
.review ion-segment,
.review .recalibrate,
.review .match-tags {
    display: none;
}
.review .fixture-card.ion-card-transparent {
    background-color: transparent;
}
.headline-number {
    font-weight: bold;
}
.review {
    width: 100%;
}

.review .pro-teaser {
    z-index: 100;
    position: relative;
}

.review .needs-calibration .pro-teaser .img{
    z-index: -1;
}

.review .pro-teaser .semi-opaque {
    opacity: 0.1;
}

.review h2 {
    margin: 0;
    text-align: center;
    font-size: 16px;
}
.review .big-stat-number {
    font-size: 80px;
    line-height: 80px;
    color: var(--ion-color-primary);
    display: block;
    font-weight: bold;
}
.review .big-stat-words {
    font-size: 18px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}
.review-share-preview .story {
    position: relative;
}
/* set size of the story page */
.review-share-preview .review {
    height: 100%;
    width: 100%;
}

.review-share-preview .stats-container .stat .main-header {
    position: relative;
}
/*.review-share-preview .stats-container .stat .main-header,*/
/*.review-share-preview .stats-container .stat .content {*/
/*    font-size: 1.2em;*/
/*}*/
.review-share-preview .stats-container .stat .footer {
    margin-right: 0;
}
.review-share-preview .stats-container .stat .row {
    padding: 0;
}
.review-share-feed .review {
    /*transform: scale(0.67);
    */
    /*transform-origin: top left;
    */
}

.review-share-feed .review .top-bit {
    overflow: hidden;
}
.review-share-feed .review .bottom-bit {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 200px;
}
.review-share-story .flames-bro,
.review-share-feed .flames-bro {
    display: none;
}

.review .fixture-card .match-officials {
    display: none;
}

.review .gps-map.no-gps-data {
    display: none;
}

.review-share-preview .review .gps-map.needs-calibration {
    display: none;
}

.review-share-story .small-text .fixture-card,
.review-share-feed .small-text .fixture-card {
    margin: 2px;
    font-size: 8px;
    padding: 0px;
}

.review-share-story .small-text .fixture-card,
.review-share-feed .small-text .fixture-card {

}

.review-share-story .small-text .fixture-card .team-row .team-name,
.review-share-feed .small-text .fixture-card .team-row .team-name,
.review-share-story .small-text .fixture-card ion-text,
.review-share-feed .small-text .fixture-card ion-text {
    font-size: 8px;
}
.review-share-story .small-text .fixture-card .venue-name,
.review-share-feed .small-text .fixture-card .venue-name,
.review-share-story .small-text .fixture-card .competition-name,
.review-share-feed .small-text .fixture-card .competition-name {
    font-size: 8px;
    height: 12px;
    line-height: 12px;
    overflow: hidden;
}
.review-share-story .small-text .fixture-card .bottom-element,
.review-share-feed .small-text .fixture-card .bottom-element {
    margin-top: 2px;
}
.review-share-story .small-text .fixture-card .card-top-container,
.review-share-feed .small-text .fixture-card .card-top-container {
    margin-bottom: 0;
}
.review-share-story .small-text .fixture-card .card-indicator,
.review-share-feed .small-text .fixture-card .card-indicator {
    margin-top: 2px;
}
.review-share-story .small-text .fixture-card .score-goal,
.review-share-feed .small-text .fixture-card .score-goal {
    font-size: 12px;
}
.review-share-story .small-text .fixture-card .team-row,
.review-share-feed .small-text .fixture-card .team-row {
    border-left-width: 3px !important;
}

.review-share-feed .map-container,
.review-share-feed .map-container .svg-pitch img,
.review-share-feed .map-container img.svg-pitch{
    width: 100%;
    height: 140px;
}
.review-share-feed .map-container canvas
{
    width: 100%;
    height: 100%;
}
