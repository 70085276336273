/* These styles are to make an ion-input of type date display correctly. Ionic expect you to
use their dat picker instead, but this is unworkable with cypress tests.
 */
input.align-text-end,
input.native-input[type="date"],
input.native-input[type="datetime-local"] {
    line-height: 44px;
    text-align: right; /* chrome desktop */
}

/* Safari/ios */
input.native-input[type="date"]::-webkit-date-and-time-value,
input.native-input[type="datetime-local"]::-webkit-date-and-time-value {
    text-align: right;
}

