.register-form ion-label {
    text-transform: uppercase
}

.welcome-text-container {
    width: 100%;
    margin-bottom: 10px;
}

.no-thanks{
    color: white;
    text-decoration: underline;
}
