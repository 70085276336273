.canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.sprint-key-container {
    display: flex;
    justify-content: center;
}

.sprint-key {
    padding: 5px;
    font-size: 12px;
    color: white;
}

.hidden {
    text-decoration-style: solid;
    text-decoration-line: line-through;
}

.ion-stop {
    padding: 5px;
    display: inline-block;
    margin-right: 5px;
}