.comp-venue{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
}

.comp-venue>*{
    max-width: 45%;
}

.bottom-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    color: #484848;
    font-size: smaller;
}

.bottom-element .match-tags{
    border-top: 1px solid var(--ion-color-step-300);
    margin-top: 5px;
    padding-top: 5px;
}
