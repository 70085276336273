.marathon-shirt {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    position: relative;
}
.marathons-count {
    color: white;
    font-weight: bold;
    font-size: 150px;
    margin: 0;
}
.marathons-label {
    color: white;
    font-weight: bold;
    font-size: 40px;
    text-transform: uppercase;
    margin: 0;
}
.distance-total-review .overlay-text {
    width: 100%;
    text-align: center;
}
.distance-total-review .info .icon {
    right: 3px;
    top: unset;
    bottom: 32px;
    position: absolute;
}

.distance-total-review .stats-container .info .lines {
    border-top: 2px dashed #ffffff;
    height: 1px;
    position: absolute;
    bottom: 25px;
    left: 30px;
    right: 20px;
}

.distance-total-review .info .content{
    width: 100%;
    padding-right: 30px;
    word-wrap: unset;
}


.distance-total-review .teaser-button-container{
    padding: 30px;
}

.distance-unit {
    font-size: 18px;
}

.distance-total-review .average-distance{
    margin: 50px 0;
}

.review-share-story .distance-total-review .average-distance {
    margin: 20px 0;
}



.distance-total-review .stats-container .stat .main-header {

    padding-right: 38px;
}
.distance-total-review .stats-container .stat .main-header > div{

    text-align: right;
    padding-right: 10px;
}

/*.distance-total-review .stats-container .header {*/
/*    display: inline-block;*/
/*    padding: 5px 10px 10px 20px;*/
/*    font-weight: bolder;*/
/*    font-size: 1.6rem;*/
/*    margin: 0 0 0 -10px;*/


/*    background: #D5E830;*/
/*    transform: skew(-10deg);*/
/*    border-radius: 0 10px 10px 0;*/
/*}*/
/*.distance-total-review .stats-container .header > div {*/

/*}*/

/*.distance-total-review .stats-container .header .icon.start {*/
/*    margin: -25px -10px -7px 0;*/
/*}*/


.review-share-story .marathon-shirt {
    transform: scale(0.85);
    transform-origin: top left;
}
.review-share-feed .marathon-shirt {
    /*display: none;
    */
}
.review-share-feed .marathon-shirt img {
    height: 150px;
    display: block;
    margin: 0 auto;
}
.review-share-feed .marathon-shirt .marathons-count {
    font-size: 60px;
    color: var(--ion-color-primary);
}
.review-share-feed .marathon-shirt .marathons-label {
    font-size: 13px;
}
