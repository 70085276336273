.border-white {
    border: 1px solid darkgray;
    background-color: transparent;
    border-radius: 20px;
}

.review-header-text {
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.centered-container-with-padding {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.grid-container-with-padding {
    display: grid;
    padding-left: 10px;
}
