.display-flex {
    display: flex;
}

.officials-team-name-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.delete-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled {
    opacity: 0.4;
}

.input-container {
    display: flex;
    width: 100%;
}

.input-number {
    width: 35px /* Set a fixed width for the first input */
}

.input-name {
    flex: 1; /* Allow the second input to occupy remaining space */
    margin-left: 10px; /* Add some spacing between the inputs */
}


ion-chip.ion-chip-small{
    height: 40px;
    min-width: 80px;
    font-size: 12px;
}

ion-select.teamofficial-select::part(container) {
    width: 100%;
}

ion-select.teamofficial-select::part(text) {
    max-width: calc(30vw - 30px);
}

.teamofficial-select {
    display: flex;
    width: 30vw;
    overflow: hidden;
    font-size: 14px;
}

