.row-container {
    align-items: center;
    color: white;
    margin: 10px;
}

.create-match-card-title {
    margin-left: 8px;
}

ion-card.create-match-wrapper {
    margin-top: 0;
}

.create-match-step {
    background: linear-gradient(180deg, #e3f075 0%, #b7ca16 100%);
    width: 24px;
    height: 24px;
    border-radius: 99px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: black;
}

.native-input.sc-ion-input-ios {
    padding-left: 5px;
}

.item-title-container {
    font-size: 16px;
    --padding-start: 12px;
}

.item-title {
    font-size: 12px;
    --padding-start: 12px;
}

.item-transparent {
    background-color: transparent;
    --background: transparent;
    border-width: 0;
    border-color: transparent;
    --border-width: 0;
    --border-color: transparent;
    height: 50px;
}

.line {
    border-bottom-width: 1px;
    border-bottom-color: var(--ion-color-step-400);
    border-style: solid;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
}
