.review-share-feed .story-footer, .review-share-story .story-footer {
    display: flex;
    flex-flow: row nowrap;
    height: 35px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #000000;
    justify-content: space-between;
    align-items: center;
}
.review-share-feed .story-footer .footer-logo, .review-share-story .story-footer .footer-logo {
    width: auto;
    height: 20px;
}

