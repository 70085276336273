.register-or-login {
    --register-footer-height: 150px;
}

.swiper-slide.register-swiper-slide {
    position: relative;
    height: calc(100vh - (var(--register-footer-height) + env(safe-area-inset-bottom)));
}


.register-slider-background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: calc(100vh - (var(--register-footer-height) + env(safe-area-inset-bottom) + 30vh));
}

.register-slider-front-content {
    display: flex;
    margin-top: 10vh;
    flex-direction: column;
    align-items: center;
}

img.register-slider-front-image {
    height: 55vh;
}

.register-refsix-image-container {
    display: flex;
    position: fixed;
    top: 5vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
}


.register-footer {
    height: calc(var(--register-footer-height) + env(safe-area-inset-bottom));
}

.register-refsix-logo{
    height: 20px;
}

/* add media query for when the screen height is more than 667 px*/
@media screen and (min-height: 670px) {
    .register-slider-front-content {
        margin-top: 13vh;
    }

    img.register-slider-front-image {
        height: 55vh;
    }

    .register-refsix-image-container {
        top: 8vh;
    }

    .register-refsix-logo{
        height: 25px;
    }
}

