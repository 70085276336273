.distance-segments {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.card-background {
    background-color: var(--ion-item-background);
}

.no-gps-data-card {
    display: flex;
    flex-direction: column;
}