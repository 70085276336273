.average-distance.referee {
    background-color: #eaf655;
    flex: 1 1 20%;
}
.average-distance.observer {
    background-color: #eaf655;
    flex: 1 1 20%;
}

.distance-review .stats-container .stat .main-header {
    flex-basis: 215px;
    text-align: end;
    text-transform: initial;
    padding: 5px 10px 10px 20px;
    margin: 0 5px 0 -20px;

}

.distance-review .stats-container .stat .content {
    font-size: 1em;
    flex-grow: 1;
}
.distance-review .stats-container .stat.total-distance .info {
    padding: 0;
}
.distance-review .stats-container .stat.total-distance .info .row {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-style: italic;
}
.distance-review .stats-container .stat.total-distance .info .distance {
    font-size: 2.4em;
    font-weight: 900;
    display: inline-block;
    margin-right: unset;
}
.distance-review .stats-container .stat.total-distance .info .unit {
    display: inline-block;
    font-weight: normal;
}

.highest-distance-in-match .score {
    display: none;
}

.distance-review .recalibrate-item {
    display: none;
}

.distance-review  .distance-container {
    text-align: center;
    font-size: 60px;
    font-weight: 900;
    font-style: italic;
}

.review-share-story .distance-review  .distance-container {
    font-size: 50px;
    margin: 20px 0;
}

.distance-review .highest-distance-in-match .content .team-name {
    font-size: 25px;
}



.review-share-story .distance-review .top-bit {
    position: relative;
    /*width: 300px;*/
    /*margin: 0 auto;*/
}

.review-share-story .distance-review .gps-map{
    width: 290px;
    margin: 0 auto;
}

.review-share-story .distance-review .highest-distance-in-match .content .team-name {
    font-size: 20px;
}

.review-share-story .distance-review .stats-container .stat .main-header{
    font-size: 28px;
}

.review-share-story .distance-review .match-header {
    width: 300px;
    margin: 5px 0;
}
.review-share-story .distance-review h2 {
    font-size: 16px;
}
.review-share-story .distance-review .card-container .card {
    gap: 0;
    margin: 0;
}
.review-share-story .distance-review .card-container .card .content {
    font-size: 2rem;
}
.review-share-feed .distance-review {
    display: flex;
    flex-direction: row;
    /*.map-container{
        */
    /* transform: scaleY(0.8);
    */
    /* transform-origin: top center;
    */
    /*
}
*/
}
.review-share-feed .distance-review .top-bit {
    flex: 1 1 70%;
}
.review-share-feed .distance-review .bottom-bit {
    flex: 1 1 30%;
}
.review-share-feed .distance-review .card-container {
    position: absolute;
    right: 0;
    top: 40px;
    width: 100%;
    flex-direction: column;
    flex-flow: column;
    gap: 5px;
    height: 72%;
    padding-top: 0;
}
.review-share-feed .distance-review .card-container .card {
    flex: 1 1 30%;
    gap: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.review-share-feed .distance-review .card-container .card .content {
    font-size: 2rem;
}
.review-share-feed .distance-review .fixture-card {
    margin: 5px;
}
.review-share-feed .distance-review.review h2 {
    font-size: 10px;
    text-align: left;
    margin-left: 10px;
}

