.wellbeing-container {
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.score-emojis {
    justify-content: space-evenly;
}

.wellbeing-icons {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px auto;
    height: 50px;
    width: 50px;
    background-image: url("../../../assets/images/wellbeing-emojis/emoji-2.png");
}

.wellbeing-icons.inactive {
    opacity: 0.35;
    -khtml-opacity: 0.35;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
    filter: alpha(opacity=35);
}

.wellbeing-icons.emoji-0 {
    background-image: url("../../../assets/images/wellbeing-emojis/emoji-0.png");
}

.wellbeing-icons.emoji-1 {
    background-image: url("../../../assets/images/wellbeing-emojis/emoji-1.png");
}

.wellbeing-icons.emoji-2 {
    background-image: url("../../../assets/images/wellbeing-emojis/emoji-2.png");
}

.wellbeing-icons.emoji-3 {
    background-image: url("../../../assets/images/wellbeing-emojis/emoji-3.png");
}

.wellbeing-icons.emoji-4 {
    background-image: url("../../../assets/images/wellbeing-emojis/emoji-4.png");
}

.wellbeing-comments-area {
    background-color: var(--ion-notes-textarea-background-color);
    padding: 5px;
    color: white;
    height: 272px;
    text-align: left;
}

.wellbeing-container h2 {
    font-size: 20px;
    margin-top: 0;
}
