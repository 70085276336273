.story-player {
    display: flex;
    flex-flow: column;
    height: 100%;
}
.story-player .stories {
    /*display: flex;
    */
    width: 100%;
    position: relative;
    justify-content: center;
    flex-grow: 1;
    /*overflow: hidden;
    */
}
.story-player .story-container {
    width: 100vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.story-player .stories .story {
    min-height: 650px;
    width: 100%;
}
.story-player .stories .hidden-story {
    visibility: hidden;
}
.story-player .stories .current-story {
    visibility: visible;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-grow: 1;
}
.story-player .stories .current-story .story {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
}
.story-player .story-player-share {
    background-color: #000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.story-player .story-player-share ion-button {
    margin-bottom: env(safe-area-inset-bottom);
    margin-top: 5px;
}

.story-player .stories .navigation-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #0c2d6b;
    opacity: 0;
    display: flex;
    flex-flow: row nowrap;
    z-index: 50;
}
.story-player .navigation-panel .left {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.story-player .navigation-panel .right {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.story-player .progress-indicator {
    display: flex;
    flex-shrink: 0;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 4px;
    gap: 3px;
    z-index: 1;

}
.story-player .progress-indicator .progress-indicator-dot {
    flex: 1 1 100%;
    display: flex;
    background-color: #919191;
    height: 100%;
}
.story-player .progress-indicator .progress-indicator-dot .progress-indicator-dot-inner {
    display: flex;
}
.story-player .progress-indicator .progress-indicator-dot.past {
    flex: 1 1 100%;
    display: flex;
    background-color: #fff;
    height: 100%;
}
.story-player .progress-indicator .progress-indicator-dot .progress-indicator-dot-inner.current {
    display: flex;
    animation: progress 15s linear 1;
    animation-fill-mode: forwards;
    background-color: #fff;
}

.story-player .paused {
    animation-play-state: paused;
}
.story-player .playing {
    animation-play-state: running;
}
@keyframes progress {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
.skewed-lines {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0.8;
}
/*.skewed-lines {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background: linear-gradient(*/
/*            to top,*/
/*            rgba(0, 0, 0, 0) 0%,*/
/*            rgba(0, 0, 0, 1) 100%*/
/*    ),*/
/*        !* Gradient overlay *!*/
/*    repeating-linear-gradient(*/
/*            20deg,*/
/*                !* Angle of the lines *! transparent 0,*/
/*            transparent 11px,*/
/*                !*d5e83000 *! rgba(213, 232, 48, 0.9) 12px,*/
/*            rgba(213, 232, 48, 0.5) 13px !* Line thickness and spacing *!*/
/*    );*/
/*    border: 1px solid black; !* Optional border *!*/

/*}*/
