.team-row {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-bottom: 5px;
}
.team-row .team-logo {
    border-radius: 100%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border: 2px solid white;
}

.team-row .team-name {
    font-size: 16px;
}

.team-row .name-cards {
    flex-basis: 75%;
}

.team-row .team-score {
    flex-basis: 15%;
    text-align: right;
    font-size: 20px;
}

.team-row .team-score .match-score {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.team-row .team-score .score-kftpm {
    font-size: 14px;
    padding-right: 10px;
}

.team-row .card-indicator{
    margin-top: 4px;
    margin-left: 1px; /* our font doesn't quite render at the start of the text box */
}
