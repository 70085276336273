.pair-watch-container {
    padding: 10px
}

.pair-watch-container ion-row {
    padding: 10px;
}

.item-pair-watch-list {
    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 1
}
