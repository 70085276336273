.finding-watch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.timer-restart-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.watch-outline-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner-top-space {
    top: 65%;
}

.onboarding-complete-page .missing-info-triangle {
    display: none;
}

.onboarding-heatmap .recalibrate-item {
    display: none;
}
