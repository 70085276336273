.segment-button {
    --indicator-color: var(--ion-color-primary);
    --background-checked: var(--ion-color-primary);
    --color: var(--ion-color-gray);
    --color-checked: black;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}

.item-segments-container {
    --background: transparent;
    --border-color: transparent;
    margin-top: 6px;
    margin-bottom: 6px;
}

ion-segment {
    width: 100%;
}

@media (max-width: 700px) {
    .fixture-segments ion-segment-button {
        /* This is specifically picked to ensure one button is cut off so it is obvious more to scroll. Also, we need to override the default to enable fitting. */
        min-width: 21vw;
    }
}

/* Hiding the scrollbar of segment*/
ion-segment::-webkit-scrollbar {
    display: none;
}

.segment-button::before {
    display: none;
}

.card-container-team-names {
    background-color: var(--ion-card-team-names);
    height: 125px;
    margin-top: -10px;
    z-index: 10;
}

.match-overview-card-margin {
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-row-container {
    height: 100%;
    align-items: center;
}

.team-color-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
}

.team-color-round {
    height: 50px;
    width: 50px;
    border-radius: 99px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-name-short {
    font-weight: bold;
    font-size: medium;
    color: white;
    mix-blend-mode: difference;
}

.name-long-wrapper {
    margin-top: 5px;
    text-align: center;
}

.text-name-long {
    color: white;
    font-size: x-small;
}

.goals {
    font-size: 40px;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
}

.penalty-score {
    color: #e3e3e3;
    font-size: 16px;
}
