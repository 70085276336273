
.onboarding {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.onboarding .exit-button button {
    color: white;
    background-color: black;
    right: 10px;
    top: 5px;
    position: absolute;
    border: 3px solid #d5e830;
    border-radius: 2px;
    margin: 5px;
    padding: 7px;
    z-index: 4;
}

.onboarding .progress {
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 5;
    background-color: black;
    border-bottom: 2px solid #d5e830;
    padding: 3px;
    border-radius: 2px;
}

.onboarding .vimeo-player {
    width: calc(((16 / 9) * 77vh));
    height: 77vh;
    position: relative;
    margin: 0 auto;
    background-color: #434343;
    max-width: 100vw;
    max-height: calc((9 / 16) * 100vw);
}

.onboarding .vimeo-player .vimeo-player-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    height: 100%;
    width: 100%;
    animation: fade-player-overlay 0.5s ease-out;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

.onboarding .vimeo-player .vimeo-player-overlay .vimeo-overlay-text {
    text-align: center;
    font-size: 18px;
}

.onboarding .video-text {
    padding: 0 10px 0 10px;
    text-align: center;
}

.onboarding .bottom-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 23vh;
}

.onboarding .bottom-menu .left,
.onboarding .bottom-menu .right {
    flex: 1;
}

.onboarding .bottom-menu .middle {
    flex: 10;
}

.onboarding .bottom-menu .button {
    color: #d5e830;
    line-height: 21px;
}

.onboarding .bottom-menu p {
    margin: 0;
}

.onboarding .bottom-menu .button-columns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vimeo-player {
    width: calc(((16 / 9) * 77vh));
    height: 77vh;
    position: relative;
    margin: 0 auto;
    background-color: grey;
    max-width: 100vw;
    max-height: calc((9 / 16) * 100vw);
}

.vimeo-player-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    height: 100%;
    width: 100%;
    animation: fade-player-overlay 0.5s ease-out;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

.vimeo-overlay-text {
    text-align: center;
    font-size: 18px;
}
