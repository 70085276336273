.accept-decline-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -2px;
    background-color: #242424;
    height: 50px;
}

.button-accept-decline {
    width: 100px;
    height: 30px;
    border-width: 1px;
    border-style: solid;
    color: white;
    background-color: #1a1a1a;
    border-radius: 5px;
}

.accept {
    border-color: green;
}

.decline {
    border-color: red;
}
