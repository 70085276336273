.item-radio-list {
    margin-bottom: 10px;
    border-radius: 10px;
}

.searchbar-input.sc-ion-searchbar-ios {
    border-radius: 5px;
    border-bottom-width: 1px;
    border-bottom-color: var(--ion-color-step-300);
    border-style: solid;
}

.radio-list-card {
    --background: #1c1c1d;
    background-color: #1c1c1d;
}
