.hardest-match-review .sprint-map ion-col {
    display: none;
}
.hardest-match-review .stats-container .stat .main-header {
    flex-basis: 190px;
    text-align: end;
}

.hardest-match-review .stats-container .stat .content {
    font-size: 1em;
    flex-grow: 1;
}

.hardest-match-review .stats-container .stat.hardest-working-match .score {
    display: none;
}
.card-container .card.low {
    background-color: #fff633;
    flex: 1 1 20%;
}
.card-container .card.medium {
    background-color: #f39021;
    flex: 1 1 20%;
}
.card-container .card.high {
    background-color: #fc3838;
    flex: 1 1 20%;
}
.hardest-match-review .stats-container .stat.hardest-working-sprints {
    justify-content: center;
}
.hardest-match-review .stats-container .stat.hardest-working-sprints .gps-map .recalibrate-item{
    display: none;
}
.hardest-match-review .stats-container .stat.hardest-working-sprints .total-sprints-list {
    padding: 0 15px;
}
.flames-bro {
    display: block;
    font-size: 30px;
}

.hardest-match-review .hardest-working-match{
    margin: 50px 0;
}

.review-share-story .hardest-match-review .hardest-working-match {
    margin: 30px 0;
}

.review-share-story .hardest-match-review h2 {
    font-size: 16px;
}
.review-share-feed .hardest-match-review .bottom-bit {
    display: flex;
    flex-direction: column;
}
.review-share-feed .hardest-match-review .card-container {
    position: absolute;
    right: 0;
    top: 60px;
    width: 30%;
    flex-direction: column;
    gap: 10px;
    height: 72%;
    flex-flow: column;
}
.review-share-feed .hardest-match-review .card-container .low, .review-share-feed .hardest-match-review .card-container .medium, .review-share-feed .hardest-match-review .card-container .high {
    border-radius: 10px 0 0 10px;
}


.review-share-feed .hardest-match-review {
    display: flex;
    flex-direction: row
}

.review-share-feed .hardest-match-review .top-bit {
    flex: 1 1 70%;
}

.review-share-feed .hardest-match-review .bottom-bit {
    flex: 1 1 30%;
}

.review-share-feed .hardest-match-review .card-container {
    position: absolute;
    right: 0;
    top: 20px;
    width: 100%;
    flex-direction: column;
    flex-flow: column;
    gap: 5px;
    height: 72%;
    padding-top: 0;
}
.review-share-feed .hardest-match-review .card-container .card {
    flex: 1 1 30%;
    gap: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.review-share-feed .hardest-match-review .card-container .card .content {
    font-size: 2rem;
}
.review-share-feed .hardest-match-review .fixture-card {
    margin: 5px;
}
.review-share-feed .hardest-match-review.review h2 {
    font-size: 10px;
    text-align: left;
    margin-left: 10px;
}
