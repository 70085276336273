.extra-line-height {
    line-height: 25px;
}

.pro-header-image{
    width: 130px;
}

.youtube-full-width iframe{
    width: 100vw;
    height: calc((9/16) * 100vw);
}

.pro-features{
    font-size: 14px;
}

.pro-product-select  ion-item{
    padding: 5px 10px;
}

.pro-product-select  ion-item p{
    color: white;
}

.pro-product-select ion-radio{
    --color-checked: black;
}

.pro-product-select  ion-item.item-radio-checked p,
.pro-product-select  ion-item.item-radio-checked span
{
    color: black;
}

.pro-product-select ion-item::part(native) {
    background: transparent;
    color: #fff;
    border-radius: 15px;
    border: 1px solid grey;
}

.pro-product-select .item-radio-checked::part(native){
    background: var(--ion-color-primary);
    --color: black;
    border: none;
}

.go-pro-modal ion-radio::part(container) {
    width: auto;
    height: auto;

    border: none;
}
.pro-product-select ion-radio::part(container){
    position: absolute;
    width: 30px;
    top: 50%;
    transform: scale(1.5) translateY(-50%);
}

.go-pro-modal ion-item,
.go-pro-modal ion-list,
.go-pro-modal ion-item::part(native)
{
    background: var(--ion-background-color);
    --background: var(--ion-item-background);
}

