.stats-container {
    padding: 15px 0 0 0;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0;
    font-size: 1rem;
}
.stats-container .stat {
    justify-content: space-around;
    display: flex ;

    flex-flow: column wrap;
    /*text-transform: uppercase;*/

}

.stats-container .stat .row {
    display: flex;
    flex-flow: row nowrap;
}

.stats-container .stat .main-header {
    color: #0d0d0d;
    text-transform: capitalize;
    display: flex;
    padding: 5px 10px 10px 20px;
    font-weight: bolder;
    font-size: 2rem;
    margin: 0 25px 0 -15px;
    background: #D5E830;
    transform: skew(-10deg);
    border-radius: 0 10px 10px 0;
    flex-grow: 1;
}
.stats-container .icon {
    z-index: 1;
    position: absolute;
    right: -25px;
    top: -14px;
}
.stats-container .main-header .icon {
    transform: skew(10deg);
}
.stats-container .stat.total-matches .icon {
    background: url('../../assets/images/review/ball.png') no-repeat center center;
    width: 75px;
    height: 75px;
    background-clip: border-box;
    background-size: cover;
}
.stats-container .stat.average-distance .icon {
    background: url('../../assets/images/review/distance.png') no-repeat center center;
    width: 75px;
    height: 75px;
    background-clip: border-box;
    background-size: cover;
    top: -22px;

}
.stats-container .stat.total-sprints .icon {
    background: url('../../assets/images/review/sprint.png') no-repeat center center;
    width: 75px;
    height: 75px;
    background-clip: border-box;
    background-size: cover;

}
.stats-container .stat .icon.start {
    width: 67px;
    height: 67px;
    margin: 0;
    background-clip: border-box;
    background-size: cover;
    display: inline-block;
}

.stats-container .stat .content {
    display: flex;
    font-size: 50px;
    font-family: Roboto, sans-serif;
    font-weight: 900;
    justify-content: center;
    text-align: center;
    color: white;
    align-items: baseline;
    margin-left: 5px;
    margin-right: 5px;
}
.stats-container .stat .footer {
    display: flex;
    color: white;
    font-size: 1rem;
    justify-content: flex-start;
    text-align: center;
    text-shadow: 0 0 3px #000000;
    padding: 0 10px 10px 0;
    margin-left: 12px;
}
.stats-container .info {
    width: 80%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 25px;
    gap: 5px;
    font-size: 1.8rem;
    display: flex;
    position: relative;
    overflow-wrap: break-word;
}

.stats-container .info .icon {
    width: 50px;
    height: 50px;
    align-content: flex-end;
}

.stats-container .match {
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    align-items: center;
    margin: 5px;
}

.stats-container  .team-container {
    text-align: center;
    /*flex-grow: 1;*/
    margin: 0 5px;
}

.stats-container  .row{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 5px;
    align-content: center;
    align-items: center;
}
.stats-container  .col{
    display: flex;
    flex-direction: column;

}
.stats-container  .team-container .team-name-container {
    display: flex;
    flex-direction: column;
}
.stats-container  .team-container .team-name-container .team-name {
    font-size: 1.3em;
    display: flex;

    font-style: italic;
    text-transform: capitalize;
    margin: 0 auto;
}
.stats-container  .team-container .vs {
    font-size: 1.8em;
    font-weight: 900;
    font-style: italic;
    color: lightgrey;
/*    skew*/
    transform: skew(-10deg);
}

.stats-container  .team-container .team-color {
    height: 6px;
    background-color: #0c2d6b;
    border-radius: 3px;
}
.stats-container .score {
    /*flex-basis: 20%;*/
    font-size: 1.5em;
    font-weight: 900;
    flex-grow: 0;
}

.stats-container .total-sprints-list {
    display: flex;
    text-align: center;
}

.stats-container .total-sprints-list .total-sprints-item .total-sprints-low .total-sprints-bar{
    background-color: #fff633;
}
.stats-container .total-sprints-list .total-sprints-item .total-sprints-medium .total-sprints-bar{
    background-color: #f39021;
}
.stats-container .total-sprints-list .total-sprints-item .total-sprints-high .total-sprints-bar{
    background-color: #fc3838;
}

.stats-container .total-sprints-list .total-sprints-item .total-sprints-content {
    font-size: 3rem;
    font-weight: 900;
    font-style: italic;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    z-index: 2;
    position: relative;
    margin-bottom: -20px;
    padding: 0 15px;
}
.stats-container .total-sprints-list .total-sprints-item .total-sprints-bar {
    min-height: 15px;
    border-radius: 7px;
    z-index: 1;
    position: relative;
}
.stats-container .total-sprints-list .total-sprints-item .total-sprints-footer {
    font-size: 1em;
    font-weight: 900;
    font-style: italic;

}

.cards-container {
    margin: 15px 10px 0 10px;

    display: flex;
    flex-flow: column wrap;
    flex: 1 1 0;
    justify-content: space-between;
    font-size: 1rem;
    border-radius: 10px;
}
.cards-container > div {
    display: flex;
    flex-flow: row wrap;
    min-height: 62px;
    align-content: space-around;
    justify-content: space-between;
    padding: 10px;
}
.cards-container .cards-yellow {
    border-radius: 0 0 20px 20px;
    background: linear-gradient(to top, rgb(141 113 2 / 90%) 0%, rgba(0, 0, 0, 0.8) 100%);

}
.cards-container .cards-red {
    border-radius: 20px 20px 0 0;
    background: linear-gradient(to bottom, rgba(70, 8, 8, 1), rgba(0, 0, 0, 0.8)); /* Full to transparent */
}

.cards-container .cards-red,
.cards-container .cards-yellow{
    align-items: center;
}



.cards-container .card-icon {
    position: relative;
    height: 51px;
    width: 42px;
}

.cards-container .card-icon .card1,
.cards-container .card-icon .card2 {
    width: 30px;
    height: 42px;
}

.cards-container .card-icon .card1 {
    /* Rectangle 2 */
    position: absolute;
    top: 1px;
    left: 1px;
    transform: rotate(-3.03deg);
    border-radius: 4px;

}
.cards-container .card-icon .card2 {
    /* Rectangle 2 */
    position: absolute;
    left: 28px;
    top: 8px;
    transform: rotate(0.93deg);
    border-radius: 4px;
}
.cards-container .card-icon .card1.red,
.cards-container .cards-red .card-icon .card1 {
    background: linear-gradient(150.46deg, #D81214 7.83%, #720A0B 157.87%);
    box-shadow: -13px 4px 11.4px rgba(0, 0, 0, 0.15);
}

.cards-container .card-icon .card2.red,
.cards-container .cards-red .card-icon .card2 {
    background: linear-gradient(150.46deg, #D81214 7.83%, #720A0B 157.87%);
    box-shadow: -13px 4px 11.4px rgba(0, 0, 0, 0.15);
}
.cards-container .card-icon .card1.yellow,
.cards-container .cards-yellow .card-icon .card1 {
    background: linear-gradient(150.46deg, #FFCC00 100%, #F39200 100%);
    box-shadow: -13px 4px 11.4px rgba(0, 0, 0, 0.15);
}

.cards-container .card-icon .card2.yellow,
.cards-container .cards-yellow .card-icon .card2 {
    background: linear-gradient(150.46deg, #FFCC00 100%, #F39200 100%);
    box-shadow: -13px 4px 11.4px rgba(0, 0, 0, 0.15);
}
.cards-container .footer {
    display: none;
}
.cards-container .header {
    display: inherit;
    background: unset;
}

.cards-container .cards-yellow .header {
    color: #FFCC00;
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: bolder;
}
.cards-container .cards-red .header {
    color: #D81214;
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: bolder;
}
.cards-container .cards-yellow .content,
.cards-container .cards-red .content {
    font-size: 2.5em;
    padding: 0 10px;
    font-weight: 900;
}

