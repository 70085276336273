.auth-input-container {
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
}

.auth-input {
    width: 30px;
    height: 35px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    outline: none;
    background-color: var(--ion-item-background);
    color: white;
}

.auth-input:focus {
    border: 1px solid var(--ion-color-primary);
}
