.rainbox-text{
    background: linear-gradient(53.13deg,#ffd33d 0,#fb8532 19.02%,#ea4a5a 37.19%,#8a63d2 56.92%,#2188ff 79.93%,#34d058 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    background-size: 300% 300%;
    background-position: 0% 50%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    animation: animateGradient 5s linear infinite;
}
@keyframes animateGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* for the image */
.image-container {
    position: relative;
    border-radius: 10px; /* Matches the image */
    overflow: hidden; /* Clips the overlay to the image */
}

.image-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(53.13deg, #ffd33d 0%, #fb8532 19.02%, #ea4a5a 37.19%, #8a63d2 56.92%, #2188ff 79.93%, #34d058 100%);
    background-size: 300% 300%;
    background-position: 0% 50%;
    opacity: 0.5; /* Adjust for transparency */
    mix-blend-mode: overlay; /* Helps blend with the image */
    pointer-events: none; /* Ensures the overlay doesn't interfere with clicks */
    animation: animateGradient 5s linear infinite;
}

.border-wrapper {
    position: relative;
    display: inline-block;
    padding: 5px; /* Thickness of the border */
    border-radius: 15px; /* Ensures rounded corners */
    background: linear-gradient(53.13deg, #ffd33d 0%, #fb8532 19.02%, #ea4a5a 37.19%, #8a63d2 56.92%, #2188ff 79.93%, #34d058 100%);
    background-size: 300% 300%;
    animation: animateGradient 5s linear infinite;
}


@keyframes animateGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}


.rainbow-button {
    --background: transparent; /* Set transparent to allow custom background */
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;

    --color: white; /* Adjust text color for contrast */

    --border-radius: 8px;
    --border-color: #000;
    --border-style: solid;
    --border-width: 1px;

    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
    --ripple-color: deeppink;

    --padding-top: 10px;
    --padding-bottom: 10px;

    position: relative;
    overflow: hidden;
}

/* Animated Gradient Background */
.rainbow-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(53.13deg, #ffd33d 0%, #fb8532 19.02%, #ea4a5a 37.19%, #8a63d2 56.92%, #2188ff 79.93%, #34d058 100%);
    background-size: 300% 300%;
    animation: animateGradient 5s linear infinite;
    z-index: -1;
    border-radius: 10px; /* To match the button */
}


ion-fab-button.rainbow-button{
    --border-radius: 50%;
}

ion-fab-button.rainbow-button::before{
    border-radius: 50%;
}
