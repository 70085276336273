.match-card-container {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
}

.match-details-title {
    font-size: x-large;
    font-weight: bold;
    color: white;
}

.match-details-text {
    font-size: medium;
    color: var(--ion-color-primary);
}

.match-details-row {
    padding: 0;
    margin: 0;
}

.col-no-padding {
    padding-left: 0;
}

.match-details-desc {
    color: white;
}

.match-detail-row {
    min-height: 50px;
    align-items: center;
}

.match-official-row {
    min-height: 30px;
    align-items: center;
}
