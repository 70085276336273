.selected-watch {
    border-style: solid;
    border-color: var(--ion-color-primary);
    border-radius: 5px;
}

.watch-box {
    margin-top: 10px;
    border-radius: 5px;
}

.watch-box-wrapper {
    width: 100%;
    display: grid;
    padding: 5px
}
