.header-image {
    display: flex;
    justify-content: center;
}

.header-ios.ion-toolbar:last-of-type {
    --border-width: 0 0 0;
}

.register-here-text {
    font-size: small;
    word-spacing: 0;
    font-weight: bold;
}

.welcome-back {
    font-weight: bold;
}

.div-top {
    width: 40%;
    height: 1px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: var(--ion-color-primary);
    position: absolute;
}

.div-bottom {
    width: 98%;
    height: 1px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #525252;
}

.register-description {
    font-size: 12px;
    font-weight: bold;
}

.text-wrapper {
    font-size: small;
}

.terms {
    color: var(--ion-color-primary);
}

.privacy {
    color: var(--ion-color-primary);
}

.ion-item-input {
    height: 80px;
}

.placeholder-input {
    --placeholder-color: var(--ion-color-step-500);
}

.flex-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flex-center-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.margin-bottom-centered-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.alert-input.color-danger {
    background-color: var(--ion-color-danger);
}