.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.watch-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120px auto;
    height: 50px;
    width: 100%;
}

.watch-image.watchOS {
    background-image: url("../../../../assets/images/watches/watchOS.png");
}

.watch-image.wearOS {
    background-image: url("../../../../assets/images/watches/wearOS.png");
}

.watch-image.tizen {
    background-image: url("../../../../assets/images/watches/tizen.png");
}

.watch-image.fitbit {
    background-image: url("../../../../assets/images/watches/fitbit.png");
}

.watch-image.garmin {
    background-image: url("../../../../assets/images/watches/garmin.png");
}

.watch-item-text {
    display: grid;
}

.otpContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.otpInputs {
    width: 30px;
    height: 30px;
}

.danger {
    --background: var(--invalid-bg);
    background-color: var(--invalid-bg);;
}
