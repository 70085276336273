.card {
    background-color: #a30c0c;
    color: white;

}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}

.content {
    flex-basis: 80%;
    vertical-align: center;
}
.vertical-hr {
    height: 100px; /* Adjust the height as needed */
    width: 0;
    border-left: 1px solid #fff; /* Adjust color and thickness as needed */

}
.vr {
    display: flex;
    flex-basis: 5%;
    justify-content: center;
}
.button {
    flex-basis: 10%;
    --background: #a30c0c;
    color: white;
    --box-shadow: none;
}