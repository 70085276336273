.calibrate-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.calibrate-buttons .button {
    display: block;
    flex-grow: 1;
}

.calibrate-instructions {
    font-size: 15px;
}

.recalibrate {
    text-decoration: underline;
    font-size: 14px;
    text-align: right;
    display: block;
    width: 100%;
    line-height: 20px;
    padding-right: 3px;
    color: white;
}


.map-container {
    position: relative;
    padding: 0;
}

.plotted-points {
    width: 100%;
    position: absolute !important;
    top: 0px;
    left: 0px;
}

.plotted-points svg:first-child {
    position: absolute;
    top: 0;
    left: 0;
}

.card-map .player-no {
    font-size: 10px;
    font-weight: bold;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-block;
    line-height: 20px;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: visible;
    white-space: nowrap;
}

.card-map .yellow .player-no {
    color: black;
    text-shadow: 0 0 5px white;
}

.card-map .red .player-no {
    color: white;
    text-shadow: 0 0 5px black;
}

.card-map .card {
    border-radius: 2px;
    display: inline-block;
    margin: 0 5px 0 5px;
    transform: none;
    border: 1px solid rgba(0, 0, 0, 0.7);
    box-shadow: none;
    width: 16px;
    height: 21px;
    text-align: center;
}

.card-map .card.yellow {
    background-color: #f9d401;
}

.card-map .card .red {
    background-color: #930303;
}

.card-map .minute {
    color: black;
    font-size: 0.7rem;
    font-weight: bold;
    width: auto;
    text-shadow: 0 0 5px white;
}

.card-map .no-gps-cards {
    display: flex;
}

.card-map .no-gps-cards .card {
    font-size: 10px;
    overflow: visible;
    white-space: nowrap;
    line-height: 21px;
    padding: 0;
    vertical-align: middle;
    position: inherit;
    text-shadow: none;
    font-weight: bold;
}

.card-map .no-gps-cards .card.yellow {
    color: #000000;
    text-shadow: 0 0 5px white;
}

.card-map .no-gps-cards .card.red {
    color: #ffffff;
    text-shadow: 0 0 5px black;
}

.card-map .no-gps-cards .minute {
    vertical-align: middle;
    position: inherit;
    color: white;
    text-shadow: none;
}

.svg-pitch img {
    width: 100%;
    /* maintain the image's aspect ratio */
    aspect-ratio: 504/343;
}
