.upgrade-banner{
    width: 100%;
    text-align: center;
}

.upgrade-banner button{
    border: none;
    background: transparent;
}

.upgrade-banner-text button{
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: var(--ion-color-primary);;
    color: #000000;
    margin: 0 auto;
}

.upgrade-banner-text button {
    padding: 5px;
}

.upgrade-banner-text img{
    height: 15px;
    margin-bottom: 5px;
}

.upgrade-banner-text span{
    font-weight: bold;
    font-size: 13px;
}

.upgrade-banner-text span.fake-button{
    background-color: #000000;
    color: var(--ion-color-primary);
    border-radius: 10px;
    display: block;
    padding: 5px;
    font-size: 13px;
    margin-top: 5px;
}
