.distance-role {
    display: flex;
    align-items: center;
}

.distance {
    margin-right: 5px;
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(100, 100, 100, 0.5);
}

.filter-result-text {
    display: flex;
    width: 100%;
    justify-content: center;
    color: var(--ion-color-primary);
    font-weight: bold;
}

.filter-results-number {
    padding-right: 5px;
}

.closed-date-card {
    border-color: darkred;
    border-style: solid;
    border-width: 1px;
}

.open-date-card {
    border-color: yellow;
    border-style: solid;
    border-width: 1px;
}
