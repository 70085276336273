.story-player-container {
    position: relative;
    height: 100%;
}
.review-share-preview {
    margin: 0 auto;
    background-color: #000;
    position: relative;
    overflow: hidden;
}

.review-share-preview .story-header .thingy-container{
    margin-top: 0;
}
.review-share-preview .content {
    z-index: 1;
}
.review-share-preview .stats-container .stat .main-header {
    /*margin-left: -50px;*/
}
.review-share-story {
    /*width: calc(70vh * 0.5625);
    */
    /*height: 70vh;
    */
    width: 360px;
    height: 640px;
}
.review-share-feed {
    /*width: 100vw;
    */
    /*height: 100vw;
    */
    max-width: 360px;
    max-height: 360px;
}
.review-share-story .review-share-preview-content {
    overflow: hidden;
    width: 360px;
    height: 640px;
}
.review-share-feed .review-share-preview-content {
    overflow: hidden;
    width: 360px;
    height: 360px;
}

.share-size {
    height: 50px;
    /*--color-checked: var(--ion-color-primary);*/
}

.share-button-icon{
    font-size: 20px;
}
