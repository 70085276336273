.datetime-calendar {
    background-color: transparent;
}

.calendar-day .calendar-day-active .calendar-day-today {
    background-color: transparent;
}

.calendar-day .calendar-day-active .calendar-day-today .calendar-day-highlight {
    background-color: transparent;
}
