.mgm-card {
    background-color: #ffffff;
    padding: 15px;
}

.mgm-card-title {
    display: block;
    font-size: 20px; 
    color: #000000; 
    text-align: center; 
    margin: 0 10% 15px;
}