.ion-header-overview {
    height: 80px;
}

.ion-header::after {
    background-image: none;
    box-shadow: 0 1px 0 #343434 !important;
}

.header-ios .ion-toolbar:last-of-type {
    --border-width: 0 0 0 0;
}
