.margin {
    margin: 20px;
}

.no-date {
    font-size: 14px;
    color: var(--ion-color-gray);
}

.dob-date {
    color: white;
}