
.match-log-container {
    margin: 5px;
}

/* Hide separator on the last event e.g. half end */
.match-log-container:first-child .match-log-event-separator-wrapper {
    display: none;
}

.match-log-time-container {
    text-align: center;
    display: flex;
    padding: 5px;
    justify-content: center;
    background-color: var(--ion-color-step-150);
    border-radius: 5px;
    white-space: nowrap;
}

.match-log-event-separator-wrapper {
    display: flex;
    justify-content: center;
}

.match-log-event-separator {
    width: 2px;
    height: 28px;
    background-color: var(--ion-color-gray);
    margin-top: 5px;
    margin-bottom: 5px;
}

.match-log-event-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.player-name-log-text,
.player-off-text {
    display: flex;
    align-items: center;
}

.log-left .player-name-log-text,
.log-left .player-off-text {
    flex-direction: row-reverse;
}

.player-name-log-text {
    font-size: 14px;
    color: var(--ion-color-alto);
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.player-off-text {
    font-size: 14px;
    color: var(--ion-color-alto);
    overflow: hidden;
}

.player-off-text span {
    margin-top: -4px;
    display: inline-block;
}

.log-left {
    flex-direction: row-reverse;
}


.log-left .match-log-event-name {
    align-items: flex-end;
}

.log-right .match-log-event-name {
    align-items: flex-start;
}
