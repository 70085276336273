#r6watchFrame {
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    border: 0;
}

/* handle the notch on ios */
.plt-ios #r6watchFrame{
    height: calc(100vh - 120px - env(safe-area-inset-bottom));
}
